import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import SEO from "../components/SEO";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2)
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "28px"
  },
  customMarkdown: {
    "& img": {
      float: "right",
      maxWidth: "220px",
      margin: "0 0 10px 10px",
      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)"
    }
  }
}));

const BlogPost =  ({ data }) => {
  const classes = useStyles();

  const post = data.markdownRemark;
  const { slug } = post.fields;
  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image || ""}
        pathname={slug}
      />
      <Layout>
        <div className={classes.root}>
          <h1 className={classes.title}>{post.frontmatter.title}</h1>
          <div
            className={classes.customMarkdown}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </Layout>
    </>
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        date
        image
      }
      fields {
        slug
      }
    }
  }
`;
